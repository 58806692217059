<template>
  <div class="element materialShadow">
    <dl class="input_list multicolumn">
      <table>
        <thead>
        <tr>
          <td class="Theme_C1_BG">{{ config.translation['setting'] }}</td>
          <td class="Theme_C1_BG">{{ config.translation['value'] }}</td>
          <td class="Theme_C1_BG"></td>
        </tr>
        </thead>
        <tbody>
        <system-settings-item v-for="item in settings" :item="item"
                              :key="item.name"></system-settings-item>
        </tbody>
      </table>
    </dl>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import SystemSettingsItem from './SystemSettingsItem';
  import { httpGet } from '../classes/httpHelper';

  export default {
    name: 'system-settings-page',
    components: { 'system-settings-item': SystemSettingsItem },
    data() {
      return {
        settings: [],
      };
    },
    computed: mapState(['config', 'user']),
    async created() {
      const data = await httpGet(`properties?userrole=${this.user.userRole}`);
      this.settings = Object.values(data).filter((p) => p.allowCustomerEdit);
    },
  };
</script>
