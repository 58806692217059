<template>
  <tr>
    <td>
      <dt>{{ item.niceName }}:</dt>
    </td>
    <td>
      <dd><textarea v-model="model.value"></textarea></dd>
    </td>
    <td v-if="user.backendDataAccess"><br>
      <button class='lsc-button lsc-rounded green' v-on:click.stop="updateProperty"
              v-html="getButtonText()"></button>
    </td>
    <td v-else></td>
  </tr>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpPut } from '@/classes/httpHelper';

  export default {
    name: 'system-settings-item',
    props: ['item'],
    data() {
      return {
        /** Internal representation, can be mutated, which the prop may not be. */
        model: this.item,
        buttonState: 'save',
      };
    },
    computed: mapState(['config', 'user']),
    watch: {
      item: {
        handler(newValue) {
          this.model = newValue;
        },
        deep: true,
      },
    },
    methods: {
      async updateProperty() {
        this.buttonState = 'working';
        const data = JSON.stringify({ name: this.model.name, value: this.model.value });
        console.log('About to update. config: ', this.config);
        const result = await httpPut('properties', data);
        console.log('Setting update returned data: ', result);
        this.buttonState = 'done';
        setTimeout(() => {
          this.buttonState = 'save';
        }, 2000);
      },

      getButtonText() {
        switch (this.buttonState) {
          case 'save':
            return this.config.translation.save;
          case 'working':
            return '<i class="fa fa-cog fa-spin spinner"></i>';
          case 'done':
            return '<i class="fa fa-check"></i>';
          default: return '';
        }
      },
    },
  };
</script>

<style scoped>
  button {
    width: 80px;
    text-align: center;
  }

  dd {
    border: none;
  }
</style>
